.mat-purple-bg {
  background-color: #D503C4 !important;
}

.mat-pink-bg {
  background-color: #D515C4 !important;
}

.mat-grey-bg {
  background-color: #747474 !important;
}

.mat-light-grey {
  color: #c2c2c2 !important;
}

.mat-light-grey-bg {
  background-color: #c2c2c2 !important;
}

.mat-green {
  color: #1c9b41;

  .mat-button-wrapper {
    color: #1c9b41;
  }
}

.mat-green-bg {
  background-color: #1c9b41 !important;
}

.mat-orange-bg {
  background-color: #e28f26 !important;
}

.mat-orange {
  color: #e28f26 !important;
}

.mat-red-bg {
  background-color: #f44336 !important;
}

.mat-pure-red-bg { 
  background-color: #FF0000 !important;
}

.mat-indigo-bg {
  background-color: #4a78bc !important;
}

.mat-blue {
  color: #4A78BC;
}

.mat-blue-bg {
  background-color: #4A78BC !important;
}

.mat-darkblue {
  color: #385e98;
}

.mat-darkblue-bg {
  background-color: #385e98 !important;
}

.mat-blue-secondary {
  color: #4a78bc;
}

.mat-blue-bg-secondary {
  background-color: #4a78bc !important;
}

.mat-light-blue {
  color: #dae9f0;
}

.mat-twitter-blue-bg {
  background-color: #17b3ee !important;
}

.mat-linkedin-blue-bg {
  background-color: #0077b5 !important;
}

.mat-facebook-blue-bg {
  background-color: #3b5998 !important;
}

.mat-whatsapp-green-bg {
  background-color: #25db4a !important;
}

.mat-light-blue-bg {
  background-color: #dae9f0 !important;
}

.mat-dark-blue {
  color: #385e98;
}

.mat-dark-blue-bg {
  background-color: #385e98 !important;
}

.mat-teal {
  color: #1C9B41;
}

.mat-teal-bg {
  background-color: #1C9B41 !important;
}

.mat-white {
  color: #ffffff !important;
}

.mat-white-bg {
  background-color: #ffffff !important;
}

.mat-gray {
  color: #58595b;
}

.mat-gray-bg {
  background-color: #58595b !important;
}

.mat-claret {
  color: #5E0013 ;

  .mat-button-wrapper {
    color: #5E0013;
  }
}

.mat-claret-bg {
  background-color: #5E0013 !important;
}

@import "theme.scss";

.ifc-card {
  padding: 0 !important;
  width: 100%;
  max-width: 450px;
  border-radius: 4px;

  .card-title {
    font-size: 24px;
    text-align: center;
    z-index: 10;
    overflow: hidden;
  }

  .card-subtitle {
    font-size: 14px;
    z-index: 10px;
    text-align: left;
    color: rgba(0, 0, 0, 0.54);
  }

  .padding-md .mat-list .mat-list-item .mat-list-item-content {
    padding: 0px;
  }

  .header {
    border-radius: 4px 4px 0 0;
    position: relative;
    color: white;
    min-height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #6189c4;

    .mat-icon {
      color: rgba(0, 0, 0, 0.54);
    }

    .mat-mini-fab .mat-icon {
      color: #fff;
    }

    .header-icon {
      z-index: 10;
    }

    .header-icon mat-icon {
      font-size: 48px;
      color: inherit;
    }

    .title {
      font-size: 18px;
    }

    .divider {
      background-color: white;
      opacity: 0.69;
      width: 80%;
      height: 1px;
    }

    .mat-caption {
      color: rgba(255, 255, 255, 0.7);
      white-space: nowrap;
    }

    .add-button {
      position: absolute;
      right: 18px;
      bottom: -18px;
      z-index: 10;
    }
  }

  .offer-list ::ng-deep .mat-list-item-content {
    padding: 0px !important;
  }

  .item-content {
    width: 50%;
    text-align: end;
  }

  mat-card-content {
    font-size: 16px;
  }

  .content {
    padding: 12px 12px 32px 12px;
    overflow: hidden;
  }

  p.mat-line {
    color: rgba(0, 0, 0, 0.54);
  }

  .business-address p.mat-line {
    color: rgba(0, 0, 0, 0.87);
  }

  .card-header {
    padding-bottom: 0px;
  }

  mat-card-content ::ng-deep mat-icon,
  .card-header .mat-icon-button {
    color: rgba(0, 0, 0, 0.54);
  }

  mat-card ::ng-deep mat-icon {
    color: rgba(0, 0, 0, 0.54);
  }

  mat-list {
    width: 100%;

    mat-icon {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  a {
    cursor: pointer;
  }

  .account-link {
    color: rgba(0, 0, 0, 0.77);
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 12px;
  }
}

.ifc-card-elevation {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0;
  border-radius: 4px;
  color: white;
  background: white;
  width: 100%;

  .card-title {
    margin-top: 0;
    margin-bottom: 3px;
  }

  .card-body {
    position: relative;

    .form-group {
      margin: 8px 0 0;
    }
  }

  .card-header {
    z-index: 3 !important;

    .card-title {
      margin-bottom: 3px;
    }

    &.card-header-text {
      display: inline-block;

      &:after {
        content: "";
        display: table;
      }
    }

    &.card-header-icon,
    &.card-header-text {
      i {
        width: 33px;
        height: 33px;
        text-align: center;
        line-height: 33px;
      }

      .card-title {
        margin-top: 15px;
        color: black;
      }

      h4 {
        font-weight: 300;
      }
    }
  }

  .card-body+.card-footer,
  .card-footer {
    padding: 0;
    padding-top: 10px;
    margin: 0 15px 10px;
    border-radius: 0;
    justify-content: space-between;
    align-items: center;

    .mat-button {
      min-width: 0 !important;
      padding: 0 8px !important;
    }
  }

  [class*="card-header-"] {
    margin: 0px 15px 0;
    padding: 0;

    .card-title+.card-category {
      color: rgba(255, 255, 255, 0.8);

      a {
        color: white;
      }
    }

    &:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
      border-radius: 4px;
      margin-top: -20px;
      padding: 15px;
    }

    .card-icon,
    .card-text {
      border-radius: 4px;
      background-color: white;
      padding: 15px;
      margin-top: -20px;
      margin-right: 15px;
      float: left;
    }

    .card-text {
      float: none;
      display: inline-block;
      margin-right: 0;

      .card-title {
        color: white;
        margin-top: 0;
      }
    }

    position: relative;
  }

  .card-header-primary {
    background-color: #4A78BC;
  }

  .card-header-blue {
    background-color: #4a78bc;
  }

  .card-header-darkblue {
    background-color: #385e98;
  }

  .card-header-secondary {
    background-color: #e28f26;
  }

  .card-header-green {
    background-color: #49AF67;
  }

  .card-header-teal {
    background-color: #1C9B41;
  }

  .card-header-gray {
    background-color: #58595b;
  }
}

.card-header-blue {
  background-color: #4a78bc;
}

.card-header-green {
  background-color: #49AF67;
}

.card-header-gray {
  background-color: #58595b;
}

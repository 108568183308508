@mixin custom-toast($bg-color, $bg-image-url) {
  background-color: $bg-color;
  border-radius: 16px !important;
  background-image: $bg-image-url;

  &:hover {
    box-shadow: 0 0 12px #0000009e !important;
    cursor: auto !important;
  }

  .toast-message {
    font-weight: 300;
    font-size: 14px;
  }

  .toast-title {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 2px;
  }

  .toast-close-button {
    font-weight: 400;
  }
}

.custom-toast-success {
  @include custom-toast(
    #1c9b41,
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iNDgiPjxwYXRoIGZpbGw9J3JnYigyNTUsMjU1LDI1NSknIGQ9Im00MjEtMjk4IDI4My0yODMtNDYtNDUtMjM3IDIzNy0xMjAtMTIwLTQ1IDQ1IDE2NSAxNjZabTU5IDIxOHEtODIgMC0xNTUtMzEuNXQtMTI3LjUtODZRMTQzLTI1MiAxMTEuNS0zMjVUODAtNDgwcTAtODMgMzEuNS0xNTZ0ODYtMTI3UTI1Mi04MTcgMzI1LTg0OC41VDQ4MC04ODBxODMgMCAxNTYgMzEuNVQ3NjMtNzYzcTU0IDU0IDg1LjUgMTI3VDg4MC00ODBxMCA4Mi0zMS41IDE1NVQ3NjMtMTk3LjVxLTU0IDU0LjUtMTI3IDg2VDQ4MC04MFoiLz48L3N2Zz4=")
  );
}

.custom-toast-error {
  @include custom-toast(
    #f44336,
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iNDgiPjxwYXRoIGZpbGw9J3JnYigyNTUsMjU1LDI1NSknIGQ9Im0zMzAtMjg4IDE1MC0xNTAgMTUwIDE1MCA0Mi00Mi0xNTAtMTUwIDE1MC0xNTAtNDItNDItMTUwIDE1MC0xNTAtMTUwLTQyIDQyIDE1MCAxNTAtMTUwIDE1MCA0MiA0MlpNNDgwLTgwcS04MiAwLTE1NS0zMS41dC0xMjcuNS04NlExNDMtMjUyIDExMS41LTMyNVQ4MC00ODBxMC04MyAzMS41LTE1NnQ4Ni0xMjdRMjUyLTgxNyAzMjUtODQ4LjVUNDgwLTg4MHE4MyAwIDE1NiAzMS41VDc2My03NjNxNTQgNTQgODUuNSAxMjdUODgwLTQ4MHEwIDgyLTMxLjUgMTU1VDc2My0xOTcuNXEtNTQgNTQuNS0xMjcgODZUNDgwLTgwWiIvPjwvc3ZnPg==")
  );
}

.custom-toast-warning {
  @include custom-toast(
    #e28f2a,
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iNDgiPjxwYXRoIGZpbGw9J3JnYigyNTUsMjU1LDI1NSknIGQ9Im00MC0xMjAgNDQwLTc2MCA0NDAgNzYwSDQwWm00NDQuMTc1LTExN3ExMi44MjUgMCAyMS4zMjUtOC42NzUgOC41LTguNjc2IDguNS0yMS41IDAtMTIuODI1LTguNjc1LTIxLjMyNS04LjY3Ni04LjUtMjEuNS04LjUtMTIuODI1IDAtMjEuMzI1IDguNjc1LTguNSA4LjY3Ni04LjUgMjEuNSAwIDEyLjgyNSA4LjY3NSAyMS4zMjUgOC42NzYgOC41IDIxLjUgOC41Wk00NTQtMzQ4aDYwdi0yMjRoLTYwdjIyNFoiLz48L3N2Zz4=")
  );
}

.custom-toast-info {
  @include custom-toast(
    #757575,
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iNDgiPjxwYXRoIGZpbGw9J3JnYigyNTUsMjU1LDI1NSknIGQ9Ik00NTMtMjgwaDYwdi0yNDBoLTYwdjI0MFptMjYuOTgyLTMxNHExNC4wMTggMCAyMy41MTgtOS4yVDUxMy02MjZxMC0xNC40NS05LjQ4Mi0yNC4yMjUtOS40ODMtOS43NzUtMjMuNS05Ljc3NS0xNC4wMTggMC0yMy41MTggOS43NzVUNDQ3LTYyNnEwIDEzLjYgOS40ODIgMjIuOCA5LjQ4MyA5LjIgMjMuNSA5LjJabS4yODQgNTE0cS04Mi43MzQgMC0xNTUuNS0zMS41dC0xMjcuMjY2LTg2cS01NC41LTU0LjUtODYtMTI3LjM0MVE4MC0zOTcuNjgxIDgwLTQ4MC41cTAtODIuODE5IDMxLjUtMTU1LjY1OVExNDMtNzA5IDE5Ny41LTc2M3QxMjcuMzQxLTg1LjVRMzk3LjY4MS04ODAgNDgwLjUtODgwcTgyLjgxOSAwIDE1NS42NTkgMzEuNVE3MDktODE3IDc2My03NjN0ODUuNSAxMjdRODgwLTU2MyA4ODAtNDgwLjI2NnEwIDgyLjczNC0zMS41IDE1NS41VDc2My0xOTcuNjg0cS01NCA1NC4zMTYtMTI3IDg2UTU2My04MCA0ODAuMjY2LTgwWiIvPjwvc3ZnPg==")
  );
}

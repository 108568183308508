@import './toastr/toastr.scss';
@import './toastr/custom-toastr.scss';
@import 'config.scss';
@import 'app-bar.scss';
@import 'body.scss';
@import 'flex.scss';
@import 'layout.scss';
@import 'general.scss';
@import 'splash-screen.scss';
@import 'colors.scss';
@import "cards";
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';
@import 'loader.scss';

.flex.fill {
    flex: 1;
}
.flex.fill.layout {
    flex: 0 1 auto;
}

.flex.wrap {
    flex-wrap: wrap;   
}

.flex.column {
    display: flex;
    flex-direction: column;
}
.flex.column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.flex.row {
    display: flex;
    flex-direction: row;
}
.flex.row-reverse {
    display: flex;
    flex-direction: row-reverse;
}
.flex.row.reverse {
    flex-direction: row-reverse;
}

.flex.center-center {
    justify-content: center;
    align-items: center;
}
.flex.center-end {
    justify-content: center;
    align-items: flex-end;
}
.flex.center-start {
    justify-content: center;
    align-items: flex-start;
}
.flex.end-center {
    justify-content: flex-end;
    align-items: center;
}
.flex.end-end {
    justify-content: flex-end;
    align-items: flex-end;
}
.flex.space-between-center {
    justify-content: space-between;
    align-items: center;
}
.flex.space-between-stretch {
    justify-content: space-between;
    align-items: stretch;
}
.flex.start-start {
    justify-content: flex-start;
    align-items: flex-start;
}
.flex.start-center {
    justify-content: flex-start;
    align-items: center;
}
.flex.start-end {
    justify-content: flex-start;
    align-items: flex-end;
}
.flex.stretch-center {
    justify-content: stretch;
    align-items: center;
}
.flex.stretch-start {
    justify-content: stretch;
    align-items: flex-start;
}
.flex.stretch-stretch {
    justify-content: stretch;
    align-items: stretch;
}

.flex.width-inherit {
    width: inherit;
}

@media screen and (max-width: 524px) {
    .flex.center-center-xs {
        justify-content: center;
        align-items: center;
    }
    .flex.column-xs {
        flex-direction: column;
    }
    .flex.end-end-xs {
        justify-content: flex-end;
        align-items: flex-end;
    }
}

@media screen and (max-width: 960px) {
    .flex.column-sm {
        flex-direction: column;
    }
}

@media screen and (max-width: 1170px) {
    .flex.column-m {
        flex-direction: column;
    }
    .flex.end-end-m {
        justify-content: flex-end;
        align-items: flex-end;
    }
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  min-width: 320px;
}

h1, h2 {
  margin: 0;
}

.break-word {
  word-wrap: break-word;
}

@import 'theme.scss';

$theme-app-bar-title-color: black;

$theme-app-bar-title-color-inverted: white;

.app-bar-expandable {
  .app-bar-background {
    background-image: url('/projects/portal/src/assets/dashboard.jpg');
    background-attachment: fixed;
    background-size: 2000px;
    background-position: right -410px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
  }

  .app-bar-background-wrapper {
    background-color: $theme-app-bar-expanded-wrapper-background-color;
    opacity: $theme-app-bar-expanded-wrapper-opacity;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    @media (max-height: 699px) {
      background-color: white;
    }
  }
}

.app-bar-expanded .app-bar-title-container--hidden-collapsed {
  display: block !important;
}

.app-sidenav-header-background {
  background-image: url('/projects/portal/src/assets/dashboard.jpg');
  background-attachment: fixed;
  background-size: 460px;
  background-position: -110px -10px;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  z-index: -2;
  width: 100%;
  height: 100%;
}

.app-sidenav-header-background-wrapper {
  background-color: $theme-app-bar-expanded-wrapper-background-color;
  opacity: $theme-app-bar-expanded-wrapper-opacity;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.colored .logo0 {
  fill: #231F20;
}

.colored .logo1 {
  fill: #158E3B;
}

.white .logo0 {
  fill: white;
}

.white .logo1 {
  fill: white;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.app-bar {
  height: 64px;
}

.app-bar-expandable .app-bar {
  color: white;

  @media (max-height: 699px) {
    color: rgba(0, 0, 0, 0.87);

    mat-icon {
      color: rgba(0, 0, 0, 0.54);
    }

    .mat-tab-link {
      color: #1c9b41;
    }

    .mat-ink-bar {
      background-color: #1c9b41 !important;
    }

    .mat-tab-link:not(.mat-tab-label-active) {
      color: black;
    }
  }
}

.mat-tab-nav-bar .mat-tab-link {
  min-width: min-content;
}

@media (max-width: 699px) {
  ifc-app-bar-expanded-tabs .mat-tab-nav-bar {
    overflow-x: auto !important;
    width: 100vw;
  }
}

.mat-tab-nav-bar {
  white-space: nowrap;
  text-transform: uppercase;
}

.app-bar-expanded .app-bar {
  height: 266px;

  @media (min-height: 700px) {
    min-height: 266px;
  }

  @media (max-height: 699px) {
    height: 64px;

    &.app-bar--tabbed {
      height: 100px;
    }
  }
}

.app-bar-expandable .app-bar-background {
  background-image: url("assets/dashboard-backoffice.jpg");
  background-size: auto;

  @media (max-height: 699px) {
    background-image: none;
  }
}

.app-bar-expandable .app-sidenav-header-background {
  background-image: url("assets/dashboard-backoffice.jpg");
}

.app-bar-container {
  &.padding {
    padding: 12px 16px;
  }

  &.padding-xs {
    padding: 12px 8px;
  }
}

@media (max-height: 699px) {
  .app-bar-expanded-container {
    margin-top: 25px;
  }
}

.app-bar-expanded .account-app-bar-expanded {
  height: 150px;
  visibility: visible;
  transition-delay: 0.2s;

  @media (max-height: 699px) {
    height: 0 !important;
    visibility: hidden;
  }
}

.ifc-card {
  @media (max-width: 420px) {
    min-width: auto;
    max-width: 100vw;
  }
}

.scroll-margin-bottom {
  margin-bottom: 100px !important;
}

.flex-std {
  flex: 1 0 auto !important;
}

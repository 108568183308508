@import 'theme.scss';

caption {
    text-align: left;
}

h3 {
    -webkit-margin-before: 0px;
}

.layout {
    &.padding {
        padding: $theme-layout-margin;
    }
    &.padding-md {
        padding: $theme-layout-margin-medium;
    }
    &.padding-sm {
        padding: $theme-layout-margin-small;
    }
    &.padding-xs {
        padding: $theme-layout-margin-xtra-small;
    }
    &.padding-bottom {
        padding-bottom: $theme-layout-margin;
    }
    &.padding-bottom-md {
        padding-bottom: $theme-layout-margin-medium;
    }
    &.padding-bottom-0 {
        padding-bottom: 0;
    }
    &.padding-left {
        padding-left: $theme-layout-margin;
    }
    &.padding-left-md {
        padding-left: $theme-layout-margin-medium;
    }
    &.padding-left-s {
        padding-left: $theme-layout-margin-small;
    }
    &.padding-left-xs {
        padding-left: $theme-layout-margin-xtra-small;
    }
    &.padding-left-0 {
        padding-left: 0;
    }
    &.padding-right {
        padding-right: $theme-layout-margin;
    }
    &.padding-right-md {
        padding-right: $theme-layout-margin-medium;
    }    
    &.padding-right-sm {
        padding-right: $theme-layout-margin-small;
    }
    &.padding-right-xs {
        padding-right: $theme-layout-margin-xtra-small;
    }
    &.padding-right-0 {
        padding-right: 0;
    }
    &.padding-top {
        padding-top: $theme-layout-margin;
    }
    &.padding-top-md {
        padding-top: $theme-layout-margin-medium;
    }
    &.padding-top-sm {
        padding-top: $theme-layout-margin-small;
    }
    &.padding-top-xs {
        padding-top: $theme-layout-margin-xtra-small;
    }
    &.padding-top-0 {
        padding-top: 0;
    }
    &.padding-horizontal {
        padding-left: $theme-layout-margin;
        padding-right: $theme-layout-margin;
    }
    &.padding-vertical {
        padding-top: $theme-layout-margin;
        padding-bottom: $theme-layout-margin;
    }
    &.padding-vertical-md {
        padding-top: $theme-layout-margin-medium;
        padding-bottom: $theme-layout-margin-medium;
    }
    &.padding-vertical-sm {
        padding-top: $theme-layout-margin-xtra-small;
        padding-bottom: $theme-layout-margin-xtra-small;
    }

    &.margin {
        margin: $theme-layout-margin;
    }
    &.margin-md {
        margin: $theme-layout-margin-medium;
    }
    &.margin-sm {
        margin: $theme-layout-margin-small;
    }
    &.margin-bottom {
        margin-bottom: $theme-layout-margin;
    }
    &.margin-bottom-sm {
        margin-bottom: $theme-layout-margin-small;
    }    
    &.margin-bottom-md {
        margin-bottom: $theme-layout-margin-medium;
    }
    &.margin-bottom-0 {
        margin-bottom: 0;
    }
    &.margin-left {
        margin-left: $theme-layout-margin;
    }
    &.margin-left-sm {
        margin-left: $theme-layout-margin-small;
    }
    &.margin-left-0 {
        margin-left: 0;
    }
    &.margin-right {
        margin-right: $theme-layout-margin;
    }
    &.margin-right-sm {
        margin-right: $theme-layout-margin-small;
    }
    &.margin-right-0 {
        margin-right: 0;
    }
    &.margin-top {
        margin-top: $theme-layout-margin;
    }
    &.margin-top-sm {
        margin-top: $theme-layout-margin-small;
    }
    &.margin-top-0 {
        margin-top: 0;
    }
    
    &.fill {
        height: 100%;
    }
    
    &.position-relative {
        position: relative;
    }
    
    &.text-transform-uppercase {
        text-transform: uppercase;
    }
    
    &.white-space-nowrap {
        white-space: nowrap;
    }

    &.visibility-collapse {
        visibility: collapse;
    }
    
    &.display-none {
        display: none;
    }
}


.mat-form-field.padding-bottom-0 .mat-form-field-wrapper {
    padding-bottom: 0;
}


@media screen and (max-width: 524px) {
    .layout {
        &.hide-xs {
            display: none;
        }
        &.padding {
            padding: 15px;
        }
        &.show-xs {
            visibility: visible;
        }
        &.show-xs {
            visibility: visible;
        }
    }
}

@media screen and (max-width: 630px) { 
    .layout {
        &.hide-s {
            display: none;
        }
        &.show-s {
            visibility: visible;
        }
    }
}

@media screen and (max-width: 1170px) {
    .layout {
        &.show-m {
            visibility: visible;
        }
        &.hide-m {
            display: none;
        }
    }
}

@media screen and (max-width: 1400px) {
    .layout {
        &.show-l {
            visibility: visible;
        }
        &.hide-l {
            display: none;
        }
    }
}
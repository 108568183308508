$mat-theme-ignore-duplication-warnings: true;
@import "@angular/material/theming";
@include mat-core();

// below defines your custom color to build a theme palette from
$ideafinancial-primary: (
  50: #1c9b41,
  100: #1c9b41,
  200: #1c9b41,
  300: #1c9b41,
  400: #1c9b41,
  500: #1c9b41,
  600: #1c9b41,
  700: #1c9b41,
  800: #1c9b41,
  900: #1c9b41,
  A100: #1c9b41,
  A200: #1c9b41,
  A400: #1c9b41,
  A700: #1c9b41,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);
$ideafinancial-accent: (
  50: #e28f26,
  100: #e28f26,
  200: #e28f26,
  300: #e28f26,
  400: #e28f26,
  500: #e28f26,
  600: #e28f26,
  700: #e28f26,
  800: #e28f26,
  900: #e28f26,
  A100: #e28f26,
  A200: #e28f26,
  A400: #e28f26,
  A700: #e28f26,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);
$theme-ideafinancial-primary: mat-palette($ideafinancial-primary);
$theme-ideafinancial-accent: mat-palette($ideafinancial-accent);
$theme-ideafinancial-warn: mat-palette($mat-red);
$theme-ideafinancial: mat-light-theme(
  $theme-ideafinancial-primary,
  $theme-ideafinancial-accent,
  $theme-ideafinancial-warn
);

@include angular-material-theme($theme-ideafinancial);

$theme-layout-margin: 24px;
$theme-layout-margin-medium: 16px;
$theme-layout-margin-small: 12px;
$theme-layout-margin-xtra-small: 8px;

$theme-app-bar-logo-height: 64px;
$theme-app-bar-logo-height-s: 30px;
$theme-app-bar-expanded-wrapper-background-color: #4a78bc;
$theme-app-bar-expanded-wrapper-opacity: 0.69;

$theme-app-footer-background-color: #58595b;
$theme-app-footer-padding: 29px;
$theme-app-footer-color: white;
$theme-app-footer-copy-font-size: 12px;
$theme-app-footer-links-font-size: 12px;

$theme-app-page-content-items-background-color: #fafafa;

$theme-card-background-color: #fafafa;

$theme-button-stroked-border-color: rgba(0, 0, 0, 0.14);

$theme-button-stroked-disabled-background-color: #fafafa;

$theme-account-select-loan-selector-color: white;

$theme-dashboard-app-bar-expanded-divider-color: rgba(150, 150, 150, 0.596);

$theme-color-green-primary: #1c9b41;
$theme-color-red-primary: #974142;
$theme-color-gray: rgba(0, 0, 0, 0.54);
$theme-color-dark-gray: rgba(0, 0, 0, 0.87);
$theme-color-orange-accent: #e28f26;
$theme-color-white-opacity: rgba(255, 255, 255, 0.87);
$theme-color-blue-anchor: #4a78bc;
$theme-color-accent: #e28f26;

@mixin tooltip {
  font-size: 14px;
  padding: 12px !important;
  max-width: 400px !important;
}

:root {
  --theme-color-primary: #1c9b41;
  --theme-button-stroked-selected-background-color: #c8e6d0;
}

a,
a:visited {
  color: #4a78bc;
  text-decoration: none;
}

a mat-icon {
  color: rgba(0, 0, 0, 0.54);
}

main footer a {
  color: inherit !important;
}

.border-rounded {
  border-radius: 3px;
}

.background-color-gray {
  background-color: #eeeeee;
}

.color-inverted {
  color: white;
}

.color-accent {
  color: $theme-color-orange-accent;
}

.mat-button,
.mat-raised-button,
.mat-flat-button {
  text-transform: uppercase;
}

.mat-button {
  color: rgba(0, 0, 0, 0.54);
}
.mat-raised-button.mat-primary {
  background-color: $theme-color-green-primary;
  color: white;
}

.mat-caption {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.mat-dialog-container {
  border-radius: 16px !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #ffffff;
}

.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-prefix
  .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-suffix
  .mat-icon-button {
  display: inline-block !important;
}

.table-container,
.table-overflow,
.table-container .footer-actions {
  border-radius: 4px;
  overflow-x: auto;
}

// level-esq theme
$level-esq-primary: (
  50: #974142,
  100: #974142,
  200: #974142,
  300: #974142,
  400: #974142,
  500: #974142,
  600: #974142,
  700: #974142,
  800: #974142,
  900: #974142,
  A100: #974142,
  A200: #974142,
  A400: #974142,
  A700: #974142,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);
$level-esq-accent: (
  50: #705b34,
  100: #705b34,
  200: #705b34,
  300: #705b34,
  400: #705b34,
  500: #705b34,
  600: #705b34,
  700: #705b34,
  800: #705b34,
  900: #705b34,
  A100: #705b34,
  A200: #705b34,
  A400: #705b34,
  A700: #705b34,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);
$theme-level-esq: mat-palette($level-esq-primary);
$theme-level-esq-accent: mat-palette($level-esq-accent);
$theme-level-esq-warn: mat-palette($mat-red);
$theme-level-esq: mat-light-theme(
  $theme-level-esq,
  $theme-level-esq-accent,
  $theme-level-esq-warn
);

// TODO all "important"s will be fixed in https://ideafinancial.atlassian.net/browse/RYNO-8667
.theme-level-esq {
  @include angular-material-theme($theme-level-esq);
  --theme-color-primary: #974142;
  --theme-button-stroked-selected-background-color: #eedada;

  .mat-button,
  .mat-raised-button,
  .mat-flat-button {
    text-transform: uppercase;
    border-radius: 24px;
  }

  .mat-button.mat-primary {
    color: $theme-color-red-primary !important;
  }

  .mat-raised-button.mat-primary {
    background-color: $theme-color-red-primary !important;
    color: white !important;
  }

  .mat-flat-button.mat-primary {
    background-color: $theme-color-red-primary !important;
  }

  .mat-raised-button.mat-primary.mat-button-disabled {
      background-color: #0000001f !important;
      color: #00000042 !important;
  }
}

.color-primary {
  color: var(--theme-color-primary) !important;
}

.bg-primary {
  background-color: var(--theme-color-primary);
}

.scroll-margin-bottom {
  margin-bottom: 100px !important;
}

.secondary-text {
  color: rgba(0, 0, 0, 0.54) !important;

  &.medium {
    font-size: 12px !important;
  }

  &.small {
    font-size: 10px !important;
  }
}

.help-text {
  text-align: start;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, .38);
  margin-top: -20px;
  margin-bottom: 12px;
  padding-left: 11px;
}

.main-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.sign-in-panel {
  max-width: 900px;
}

.sign-in-panel .layout.margin {
  width: 100%;
}

.sign-in-panel .app-card-content {
  max-width: 454px !important;
  margin: 0px !important;
}

.sign-in-panel .app-card-content {
  background-color: #ffffff;
  margin: 0px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
}

.sign-in-panel form {
  max-width: 100vw;
  width: 100%;
}

@media screen and (max-width: 899px) {

  .sign-in-panel .app-card.fixed-width .app-card-content,
  .sign-in-panel .app-card.fixed-width .app-card-aside {
    width: 100% !important;
  }
}

@media (min-width: 900px) {

  .sign-in-panel .app-card.fixed-width .app-card-content,
  .sign-in-panel .app-card.fixed-width .app-card-aside {
    width: 50vw !important;
    max-width: 454px !important;
  }
}

@media (max-width: 599px) {
  .sign-in-panel .mat-form-field-subscript-wrapper {
    top: 56px !important;
  }

  .sign-in-panel mat-form-field {
    margin-top: 10px !important;
  }
}

@media (min-width: 600px) {
  .sign-in-panel mat-form-field {
    margin-top: 0px !important;
  }
}

.mat-form-field-subscript-wrapper {
  font-size: 10px !important;
}

.green-button.mat-raised-button.mat-primary {
  min-width: 146px;
}

.green-button.mat-raised-button {
  width: 100%;
}

@media (max-width: 900px) {
  .sign-in-panel .app-card-aside {
    display: none !important;
  }

  .green-button {
    width: 100%;
    margin-top: 25px;
  }

  .login-button-row {
    flex-direction: column !important;
  }

  .sign-in-panel .layout.margin {
    flex-direction: column !important;
  }
}

@media (min-width: 900px) {
  .sign-in-panel .app-card-aside {
    min-height: 200px;
  }

  .sign-in-panel .app-card-aside {
    display: block !important;
    order: -1 !important;
  }

  .sign-in-panel {
    min-width: 900px;
  }
}

.sign-in-panel .app-card-aside,
.ifp-user-sign-out-form .app-card-aside {
  padding: 24px 24px 0px 24px !important;
}

.user-sign-in-form-submit-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin: 5px 0px 5px 0px;
  text-align: center;
}

ifp-transfer-bank-account-widget,
ifp-bank-widget {
  min-width: 300px !important;
  max-width: 426px !important;
  width: 100% !important;
  display: inline-flex;
}

.user-aside-hr {
  width: 100px;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.54);
  border: none;
  margin: 60px 0 60px 0;
}

.ifc-app-scroll-container .widget {
  max-width: 392px;
}

.ifp-customer-kba-form .app-card-side {
  min-height: 190px !important;
}

@media (min-width: 960px) {
  .ifp-customer-kba-form .app-card {
    align-items: start !important;
  }
}

@media (max-width: 959px) {
  .ifp-customer-kba-form .app-card-content {
    width: 100% !important;
  }
}

@media (max-width: 899px) {

  .ifp-customer-kba-form .app-card-frame,
  .ifp-customer-kba-form .app-card-aside {
    max-width: calc(100% - 24px);
    width: 100%;
  }
}

@media (min-width: 900px) {

  .ifp-customer-kba-form .app-card-aside,
  .ifp-customer-kba-form .app-card-content {
    max-width: 435px !important;
  }

  .ifp-customer-kba-form .app-card-frame {
    width: 100% !important;
    place-content: center !important;
  }
}

.ifp-dashboard-content .ifc-card-elevation {
  padding-bottom: 8px;
}

.mat-radio-label-content {
  white-space: initial !important;
}

ifp-advisor-widget {
  margin: 16px;
}

p.mat-display-3.title {
  margin: 0px;
  font-size: 48px !important;
  font-weight: 400;
}

p.mat-display-1.aside {
  font-size: 30px;
  line-height: 40px;
}

@media (max-width: 421px) {
  .mat-form-field-subscript-wrapper {
    top: 56px !important;
  }

  mat-form-field {
    margin-top: 10px !important;
  }
}

@media (max-width: 452px) {
  .ifp-dashboard-content .mat-expansion-panel-body {
    padding: 0;
  }
}

@media (min-width: 1050px) {
  .ifp-loc-agreement-form .app-card-aside {
    max-width: 45% !important;
  }
}

@media (max-width: 1049px) {
  .ifp-loc-agreement-form .app-card-aside {
    max-width: 33% !important;
  }
}

@media (min-width: 599px) {
  .availableFunds {
    display: none !important;
  }
}

.ifb-about {
  max-width: 100vw;
  width: 100%;
}

.ifb-about .aside {
  max-width: calc(100% - 64px);
}

.ifp-user-sign-out-form .app-card-aside {
  width: 100% !important;
}

.mat-expansion-panel {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-form-field-subscript-wrapper {
  top: calc(100% - 2.1em) !important;
  font-size: 10px !important;
}

mat-form-field {
  margin-top: 0px !important;
}

.filter-toolbar {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  height: 64px;
}

.form-field-xl {
  width: 650px !important;

  mat-form-field {
    width: 650px !important;
  }
}

.form-field-lg {
  width: 452px !important;

  mat-form-field {
    width: 452px !important;
  }
}

.form-field-md {
  width: 345px !important;

  mat-form-field {
    width: 345px !important;
  }
}

.form-field-half-md {
  width: 166px !important;

  mat-form-field {
    width: 166px !important;
  }
}

.form-field-third-md {
  width: 107px !important;

  mat-form-field {
    width: 107px !important;
  }
}

.form-field-sm {
  width: 300px !important;

  mat-form-field {
    width: 300px !important;
  }
}

.form-field-xs {
  width: 260px !important;

  mat-form-field {
    width: 260px !important;
  }
}

.mat-display-3 {
  font-size: 40px !important;
}

.mat-display-2 {
  font-size: 24px !important;
}

.mat-caption {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.card {
  &.lg {
    width: 452px !important;
  }

  &.md {
    width: 392px !important;
  }

  &.xs {
    width: 100% !important;
    max-width: 392px;
  }
}

.message-box {
  margin: 24px;
  padding: 16px;

  &.info {
    .icon {
      color: #797979;
    }
  }
}

.more-actions-menu {
  margin-right: 8px !important;

  .mat-icon-button {
    margin-right: 8px !important;
  }
}

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.54);

  &.square {
    border-radius: 0;
  }

  &.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    padding: 1px 0 0 0;

    &.disabled-mat-blue {
      background-color: #366194;
      color: #015988 !important;
    }

    &.disabled-mat-green {
      background-color: #00776b;
      color: #1C9B41 !important;
    }

    &.disabled-mat-gray {
      background-color: #3f4041;
      color: #525354 !important;
    }
  }

  &.medium {
    width: 24px;
    min-width: 24px;
    height: 24px;
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    padding: 2.3px 0 0 0;

    &.letter {
      padding: 2.5px 0 0 0 !important;
    }
  }

  &.big {
    width: 60px;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
  }

  &.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px;
  }
}

.avatar-wrapper {
  position: relative;

  .avatar {
    margin-top: 0;
    margin-bottom: 0;
  }

  mat-icon.status {
    position: absolute;
    top: 28px;
    left: 28px;
  }
}

.full-width {
  width: 100%;
}

.no-padding-dialog .mat-dialog-container {
  padding: 0;
}

.snackbar {
  margin-bottom: 1rem !important;
  border-radius: 4px !important;
  @include mat-elevation(4);
}

.snackbar.error button {
  color: white;
}

.overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-list-item-full-text {
  padding: 8px 0 !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}

.no-wrap {
  white-space: inherit !important;
}

.pointer {
  cursor: pointer;
}

.badge {
  display: flex;
  align-items: center;
  min-width: 20px;
  height: 20px;
  padding: 0 7px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 20px;
  transition: opacity 0.2s ease-in-out 0.1s;
  position: absolute;
  color: #ffffff;

  &.left {
    margin-left: -52px;
  }

  &.right {
    margin-right: -52px;
  }
}

.table-top {
  height: 64px;
}

.mat-button-green {
  background-color: #1c9b41 !important;
  border-radius: 20px !important;
  color: #f4f4f4 !important;
}

.mat-button-green-border {
  border-radius: 20px !important;
  color: #1c9b41 !important;
  border: 1px solid #1c9b41 !important;
}

.mat-button-orange-border {
  border-radius: 20px !important;
  color: #e28f26 !important;
  border: 1px solid #e28f26 !important;
}

.ifc-app-scroll-container-center {
  height: 100%;
  justify-content: center;
}